import React from "react";
import style from "./Header.module.css";
import logo from "../../../themefolder/xfinity/logo.webp";
function Header({ number }) {
  console.log(number);
  return (
    <nav className={style.main_nav}>
      <div className={style.container}>
        <img className="img-fluid" src={logo} alt="Logo" />
        <div className={style.nav_btn}>
          <a href={`tel:${number}`}>{number}</a>
        </div>
      </div>
    </nav>
  );
}

export default Header;
