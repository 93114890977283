import React from "react";
import style from "./XfinityWrapper.module.css";
import wrapperimg2 from "../../../themefolder/xfinity/wrapper-img2.jpg";
import cabletv from "../../../themefolder/xfinity/cable-tv.webp";
import pc from "../../../themefolder/xfinity/pc.webp";
import wifi from "../../../themefolder/xfinity/wifi.webp";
function XfinityWrapper({ number }) {
  return (
    <div className={style.container}>
      <div className={style.banner}>
        <div className={style.banner_img}>
          <img
            className="img-fluid"
            src={wrapperimg2}
            alt="xfinity internet customer service"
          />
          {/* <Img src={`${process.env.NEXT_PUBLIC_IMAGES_URL}/wrapper-img2.WebP`} alt="Xfinity Internet offers, Xfinity Internet deals" 
            sizes={{
              default: [41,32],
              mobile: [100,65],
          }}
          /> */}
        </div>
        <div className={style.banner_text}>
          <h6>
            <span>/</span> Save big on fast, reliable Internet speeds and all
            the live TV, sports, and entertainment you love with Xfinity
            Comcast!
          </h6>
          <div className={style.banner_text_list}>
            <img className="img-fluid" src={cabletv} alt="checkmark" />
            <p>Fast, reliable connections for all your devices</p>
          </div>
          <div className={style.banner_text_list}>
            <img className="img-fluid" src={pc} alt="checkmark" />
            <p>Live TV, On Demand, sports & streaming apps all in one place</p>
          </div>
          <div className={style.banner_text_list}>
            <img className="img-fluid" src={wifi} alt="checkmark" />
            <p>DVR you can set and watch anytime, anywhere</p>
          </div>
          <div className={style.banner_btn}>
            <a href={`tel: ${number}`}>{number}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default XfinityWrapper;
