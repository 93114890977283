import CardsSection from "./CardsSection/CardsSection";
import Faqs from "./FAQS/Faqs";
import Header from "./Header/Header";
import Banner from "./Banner/Banner";
import ServiceArea from "./ServiceArea/ServiceArea";
import PlansCard from "./PlansCard/PlansCard";
import Disclaimer from "./Disclaimer/Disclaimer";
import Popup from "../popup/Popup";
import logo from "../../themefolder/verizon/logo3.png";

export default function Home() {
  return (
    <>
      <main>
        <Header />
        <Banner />
        <ServiceArea />
        <CardsSection />
        <PlansCard />
        <Faqs />
        <Disclaimer />
        <Popup
          color="linear-gradient(90deg,#ff7f7f 28%,#a80909)"
          url={logo}
          logoWidth="200px"
          number="1 (888) 532-2024"
          text="Call to Cancel your Internet Now"
        />
      </main>
    </>
  );
}
