import React from "react";
import styles from "../section1/Section.module.css";
import SpectrumOneLogo from "../../../themefolder/spectrum/Spectrum_One_Logo.webp";
import devicelockup from "../../../themefolder/spectrum/device-lockup.webp";
function Section({ number }) {
  return (
    <div
      className={styles.section}
      style={{
        backgroundImage: `url(/themefolder/spectrum/specterumone-hero.png)`,
      }}
    >
      <div className={styles.container}>
        <div className={styles.area1}>
          <div className={styles.spect_one}>
            <h2>Save with Spectrum One</h2>
          </div>
          <div className={styles.para_text}>
            <p>
              Experience seamless connectivity across all your devices.
              Experience the ultimate trifecta of connectivity with Spectrum
              One! Enjoy Spectrum Internet® for lightning-fast browsing,
              Advanced WiFi for seamless streaming, and an Unlimited Mobile line
              for staying connected on-the-go, all bundled together at an
              unbeatable price.
            </p>
          </div>
          <div className={styles.para_line}>
            <p>Internet + Advanced WiFi + Mobile</p>
          </div>
          <div className={styles.dollar_main}>
            <div className={styles.price}>
              <span>$</span>
              <h2>49</h2>
            </div>
            <div className={styles.dollar_main2}>
              <div className={styles.membership}>
                <h3>99</h3>
                <span>/mo</span>
              </div>
              <p>for 12 mos when bundled</p>
            </div>
          </div>
        </div>

        <div className={styles.image_area}>
          <div className={styles.spect_one_logo}>
            <img
              className="img-fluid"
              src={SpectrumOneLogo}
              alt=" spectrum internet provider, spectrum internet provider near me"
            />
          </div>
          <div className={styles.device_pic}>
            <img
              className="img-fluid"
              src={devicelockup}
              alt="spectrum internet deals"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
