import React from "react";
import Navbar from "../Navbar/Navbar";
import "./Internet.css";
import InternetTower from "../../themefolder/julian-VIrnnqGM8sE-unsplash(1).jpg";
import Footer from "../Footer/Footer";

function Internet() {
  return (
    <>
      <Navbar />
      <div className="w-full mt-4 d-flex justify-content-center">
        <div id="text-div" className="col-12 col-md-5 col-lg-6 px-3">
          <h1 className="fw-normal">Internet</h1>
        </div>
      </div>
      <div className="w-full d-flex justify-content-center" id="main-text-div">
        <div
          className="row d-flex align-items-center justify-content-between px-2"
          id="main-div-1"
        >
          <div className="col-11 col-md-5 col-lg-6" id="texts-div">
            <h2 id="header-text" className="fw-normal">
              Fiber-Optic Excellence
            </h2>
            <p id="text-1">
              Experience Fiber Optic Excellence with
              <a
                href="https://cancel-internet.com"
                target="_blank"
                title="https://cancel-internet.com"
                id="anchor"
              >
                cancel-internet.com
              </a>
            </p>
            <p id="text-2">
              Enter a realm of unmatched speed and reliability where quality
              meets innovation. Welcome to
              <a id="anchor" href="http://internetplans.us/" target="_blank">
                cancel-internet.com
              </a>
              – your ultimate destination for exceptional fiber optic internet
              plans, providing speeds of up to multiple Gbps tailored to your
              specific requirements.
            </p>
            <p id="text-14">Tailored Fiber Optic Solutions</p>
            <p id="text-3">
              At
              <a id="anchor" href="http://internetplans.us/" target="_blank">
                cancel-internet.com
              </a>
              , we're committed to delivering a personalized internet
              experience. Our meticulously crafted plans integrate cutting-edge
              technology and customization to elevate every facet of your
              digital life. Enjoy a connection tailored to suit your needs,
              whether it's for gaming, streaming, work, or everyday browsing.
            </p>
            <p id="text-4">Unparalleled Speed and Performance</p>
            <p id="text-5">
              Embark on an internet journey that's instantaneous, powered by our
              advanced fiber optic technology. Revel in up to 1Gbps of
              consistent speed, making downloads, uploads, and streaming tasks
              instantaneous. Say goodbye to lag and buffering with
              <a
                href="https://cancel-internet.com"
                target="_blank"
                title="https://cancel-internet.com"
                id="anchor"
              >
                cancel-internet.com
              </a>
              .
            </p>
            <p id="text-6">Nationwide Coverage</p>
            <p id="text-7">
              With
              <a
                href="https://cancel-internet.com"
                target="_blank"
                title="https://cancel-internet.com"
                id="anchor"
              >
                cancel-internet.com
              </a>
              , premium connectivity is accessible nationwide. Our expansive
              network ensures exceptional speed and reliability reach every
              corner of the nation, be it urban or rural.
            </p>
            <p id="text-8">Secure Connections</p>
            <p id="text-9">
              Data security is our top priority at
              <a
                href="https://cancel-internet.com"
                target="_blank"
                title="https://cancel-internet.com"
                id="anchor"
              >
                cancel-internet.com
              </a>
              . Each connection is fortified with robust security protocols,
              guaranteeing privacy and safety in every digital interaction.
            </p>
            <p id="text-10">Dedicated Customer Support</p>
            <p id="text-11">
              Our commitment at
              <a id="anchor" href="http://internetplans.us/" target="_blank">
                cancel-internet.com
              </a>
              goes beyond connectivity to delivering exceptional service
              experiences. Our proficient support team is always on hand to turn
              your queries and needs into solutions.
            </p>
            <p id="text-12">
              Embark on the
              <a
                href="https://cancel-internet.com"
                target="_blank"
                title="https://cancel-internet.com"
                id="anchor"
              >
                cancel-internet.com
              </a>
              Experience
            </p>
            <p id="text-13">
              Your gateway to unparalleled speed, flawless reliability, and
              personalized internet plans awaits. Every offering at
              <a id="anchor" href="http://internetplans.us/" target="_blank">
                cancel-internet.com
              </a>
              embodies innovation, customization, and forward-thinking
              solutions. Immerse yourself in a world where 1Gbps speed is
              standard, and each plan introduces you to a realm of digital
              excellence and innovation. Your bespoke fiber optic experience,
              characterized by speed, security, and supreme quality, awaits at
              <a id="anchor" href="http://internetplans.us/" target="_blank">
                cancel-internet.com
              </a>
              .
            </p>
          </div>
          <div className="col-12 col-md-7 col-lg-5">
            <img src={InternetTower} alt="" srcset="" id="Internet-Tower" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Internet;
