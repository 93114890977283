import React from "react";
import "./ServiceArea.css";

const ServiceArea = () => {
  return (
    <div className="w-100 d-flex justify-content-center servicearea-parent">
      <div className="servicearea-main">
        <p className="servicearea-header">
          Check which internet service is available in your area
        </p>
        <p className="servicearea-description mt-3">
          Find out whether Fios Home Internet, 5G Home Internet or LTE Home
          Internet is available in your area
        </p>
        <a className="servicearea-button mt-4" href={`tel:${+18885322024}`}>
          Call Us
        </a>
      </div>
    </div>
  );
};

export default ServiceArea;
