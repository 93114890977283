import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Internet from "./Component/Internet/Internet";
import Home from "./Component/homePage/home.jsx";
import CableTv from "./Component/CableTV/CableTV";
import PrivacyPolicy from "./Component/PrivacyPolicy/Policies";
import DisclaimerPage from "./Component/Disclaimer/Disclaimer";
import Spectrum from "./Component/spectrum/mainFile.jsx";
import Xfinity from "./Component/xfinity//mainFile.jsx";
import Verizon from "./Component/Verizon/mainFile.jsx";

// import Spectrum from "../src/Component/spectrum/mainFile.jsx";
// import Footer from "./Component/Footer/Footer"
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/internet" element={<Internet />} />
          <Route path="/cabletv" element={<CableTv />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<DisclaimerPage />} />
          <Route path="/cancel-spectrum" element={<Spectrum />} />
          <Route path="/cancel-xfinity" element={<Xfinity />} />
          <Route path="/cancel-verizon" element={<Verizon />} />
          {/* <Route path="/cancel-spectrum" element={<Spectrum />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
