import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "../PlansCard/PlansCard.css";
import image from "../../../themefolder/perk-tile-apple-one-3x2-default.webp";
import { FaApple } from "react-icons/fa";
function FirstPlansCard() {
  return (
    <Card className="FirstPlansCard" style={{ width: "100%" }}>
      <Card.Body>
        <div className="heading-txt-rapo d-flex justify-content-between mb-3">
          <div className="heading-rapo" style={{ width: "50%" }}>
            <h6>The ultimate iPhone plan, with Apple One</h6>
          </div>
          <div className="txt-rapo" style={{ width: "50%" }}>
            <h6>
              $65 <span>/line*</span>
            </h6>
            <span className="span-two">
              $9.95/lines <br /> perk savings
            </span>
          </div>
        </div>
        <div className="red-box ">
          <h3>
            <span className="text-white">Unlimited Ultimate</span>
          </h3>
        </div>
        <div className="brands-box mt-3">
          <p>Apple One</p>
          <img className="img-fluid" src={image} alt="" />
          <span>
            <FaApple />
            One
          </span>
        </div>
      </Card.Body>
    </Card>
  );
}

export default FirstPlansCard;
