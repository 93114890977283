import React from "react";
import "./DisclaimerSection.css";
const DisclaimerSection = () => {
  return (
    <>
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12  d-flex justify-content-center align-items-center text-center">
              <div className="Disclaimer-heading-txt-rapo">
                <h2>Disclaimer</h2>
                <p className="text-muted fw-bold mt-2">
                  This is NOT an official website of Spectrum but is the Official Site of
                  cancel-internet.com
                  (Marketing Partner). We may send updates to the mobile
                  number/email ID registered with us. The content is for
                  information purposes only and does not constitute an offer to
                  avail of any service. Prices mentioned are subject to change
                  without notice and properties mentioned are subject to
                  availability. Images are for representation purposes only. The
                  logos and images used on this website are the exclusive
                  property of their respective owners and are protected under
                  applicable copyright laws.{" "}

                  https://cancel-internet.com

                  does not claim any ownership or rights to these materials and
                  they are used on this website solely for informational
                  purposes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DisclaimerSection;
