import React from "react";
import "./Banner.css";
import "../BannerCard/BannerCard.css";
import BannerCard from "../BannerCard/BannerCard";
function Banner() {
  return (
    <>
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-txt-btn-main-rapo my-5">
                <div className="heading my-5">
                  <h1>myHome</h1>
                  <p>
                    Create the plan you want and only
                    <br />
                    pay for what you need
                  </p>

                  <a href="#">Get started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BannerCard />
      </section>
    </>
  );
}

export default Banner;
