import React from "react";
import "./OurServicesSection.css";
import Cardoneimage from "../../themefolder/benjamin-dada-EDZTb2SQ6j0-unsplash.jpg";
import Cardtwoimage from "../../themefolder/erik-mclean-U-Vu_r6qyyU-unsplash.jpg";
import Cardthreeimage from "../../themefolder/mollie-sivaram-yubCnXAA3H8-unsplash.jpg";
const OurServicesSection = () => {
  return (
    <>
      <section className="OurServicesSection">
        <div className="container">
          <div className="OurServicesSection-heading my-5">
            <h2 className="text-dark fw-normal">Our Services</h2>
          </div>
          <div className="row mb-5">
            <div className="col-lg-4">
              <div className="card border border-white border-0 ">
                <img
                  src={Cardoneimage}
                  className="OurServicesSectionCard-Images"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title text-muted">Internet</h5>
                  <h3 className="card-heading text-dark">Starting at $49.99</h3>
                  <p className="card-text text-muted">
                    Discover cable plans tailored to your preferences with
                    <a className=" text-dark ms-2" href="#">
                      cancel-internet.com
                    </a>
                    . Experience top-tier picture quality, personalized viewing
                    options, and exceptional customer service for an immersive
                    entertainment experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card border border-white border-0">
                <img
                  src={Cardtwoimage}
                  className="OurServicesSectionCard-Images"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title text-muted">HomePhone</h5>
                  <h3 className="card-heading text-dark">Starting at $19.99</h3>
                  <p className="card-text text-muted">
                    At
                    <a className=" text-dark ms-2" href="#">
                      cancel-internet.com
                    </a>
                    ,connect socially or conduct business seamlessly with our
                    dependable and cost-effective landline plans from leading
                    providers in the USA. Enjoy crystal-clear sound quality and
                    access multiple features to meet your communication needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card border border-white border-0">
                <img
                  src={Cardthreeimage}
                  className="OurServicesSectionCard-Images"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title text-muted">Cable TV </h5>
                  <h3 className="card-heading text-dark">Starting at $64.99</h3>
                  <p className="card-text text-muted text-start">
                    At
                    <a className=" text-dark ms-2" href="#">
                      cancel-internet.com
                    </a>
                    ,we provide lightning-fast internet speeds reaching multiple
                    Gbps, facilitated through DSL, fiber-optic, or satellite
                    connections. Our offerings feature affordable rates and
                    customizable features to suit your needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="OurServicesSection-button d-flex justify-content-center">
              <a
                className=" border border-white border-3"
                href="tel:+1(888)-412-5051"
              >
                Call Us
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurServicesSection;
