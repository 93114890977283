import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./Disclaimer.css";

function Disclaimer() {
  return (
    <>
      <Navbar />
      <div className="w-full mt-4 d-flex justify-content-center">
        <div id="text-div" className="col-12 col-md-5 col-lg-6 px-3">
          <h1 className="fw-normal">Disclaimer</h1>
        </div>
      </div>
      <div className="w-full d-flex justify-content-center">
        <div id="Disclaimer-text-main">
          <h2 id="Disclaimer-text">
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            is not responsible for any content, code, or any other inaccuracies.
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            makes no guarantees. In no event shall
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            be liable for any special, direct, indirect, consequential, or
            incidental damages or any damages whether in an action of contract,
            negligence or other tort arising out of or in connection with the
            use of the Service or the contents of the Service.
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            reserves the right to make additions, deletions, or modification to
            the contents of the Service at any time without prior notice.
            <br />
            The
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            Service and its content are provided "as is" and "as available"
            without any warranty or representations of any kind, either express
            or implied.
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            is a distributor and not a publisher of content provided by third
            parties; as such,
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            exercises no editorial control over such content and makes no
            warranty or representation as to the accuracy, reliability, or
            currency of any information, content, services, or merchandise
            provided or accessible through the
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            Service. Without limiting the foregoing,
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            specifically disclaims all warranties and representations in any
            content transmitted on or in connection with the
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            Service or on sites that may appear as links on the
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            Service, or in the products provided as part of or in connection
            with the
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            Service, including without limitation any warranties of
            merchantability, fitness for a particular purpose, or
            non-infringement of third-party rights. No oral advice or written
            information given by
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com ,
            </a>
            nor its affiliates, employees, officers, directors, agents, or the
            like shall create a warranty. Pricing and availability information
            is subject to change without notice. Without limiting the foregoing,
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            does not warrant that the
            <a
              href="https://cancel-internet.com"
              title="https://cancel-internet.com"
            >
              cancel-internet.com
            </a>
            Service will be uninterrupted, uncorrupted, timely, or error-free.
          </h2>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Disclaimer;
