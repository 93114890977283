import React, { useState } from 'react';
import style from './XfinityFAQS.module.css';
import { data } from './FAQsData';
import MyFAQs from './MyFAQS';

const XfinityFAQS = () => {
  const [datas, setDatas] = useState(data);

  return (
    <div className={style.Acordition}>
      <div className={style.faq}>
        <h1>FAQS</h1>
        <div className={style.acordition_block}>
          {datas.map((element) => (
            <MyFAQs key={element.id} {...element} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default XfinityFAQS;
