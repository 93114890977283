import React from "react";
import "./SectionFaqs.css";
import { CiSquareCheck } from "react-icons/ci";
const SectionFaqs = () => {
  return (
    <>
      <section className="mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mini-heading d-grid justify-content-center align-items-center text-center mb-5">
                <p className="text-dark text-center pt-2 ">FAQ'S</p>
                <h2 className="text-dark ">What makes us different?</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12  ">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button gap-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <CiSquareCheck />
                      Find best internet in your area?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        The first step is to call us at
                        Yourinternetprovider.com. We'll find the best internet
                        options in your area with just a phone call. Enjoy a
                        hassle-free experience as we provide you with a list of
                        ISPs available near you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed gap-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <CiSquareCheck />
                      Check the speed of your internet
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A unique feature of Your Internet Provider is its
                        ability to compute and present the required speed
                        options based on the user's input about the number and
                        kind of devices. The user can choose the bundles they
                        require because of this functionality.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed gap-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <CiSquareCheck />
                      All Kinds Of Entertainment In One Place
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Discover everything you need to know about our
                        comprehensive entertainment packages. From setup to
                        troubleshooting, our FAQs cover all your questions to
                        ensure you get the most out of your internet and cable
                        TV services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed gap-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <CiSquareCheck />
                      Flexible Pricing Plans And Bundles
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Find answers to your questions about our versatile
                        pricing plans and bundles. Our FAQs provide detailed
                        information on how to customize and maximize your
                        internet and cable TV services to fit your budget and
                        needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionFaqs;
