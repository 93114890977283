import React from "react";
import style from "./XfinityBundls.module.css";

const XfinityBundls = ({
  text,
  fast,
  list,
  tick,
  list2,
  list3,
  bottomText,
  foot,
  spn,
  btn,
  number,
}) => {
  return (
    <div className={style.container}>
      <div className={style.division_head}>
        <div className={style.availability_text}>
          <span>{fast}</span> <p>{text}</p>
        </div>
        <div className={style.main_list}>
          <div className={style.division_list}>
            <img src={tick} alt="Checkmark_image" />
            <p>{list}</p>
          </div>
          <div className={style.division_list}>
            <img src={tick} alt="Checkmark_image" />
            <p>{list2}</p>
          </div>
          <div className={style.division_list}>
            <img src={tick} alt="Checkmark_image" />
            <p>{list3}</p>
          </div>
        </div>
        <div className={style.division_body}>
          <p>{bottomText}</p>
        </div>
        <div className={style.division_foot}>
          <h4>
            {foot}
            <span>{spn}</span>
          </h4>
          <a href={number}> {btn}</a>
        </div>
      </div>
    </div>
  );
};

export default XfinityBundls;
