import React from "react";
import style from "./XfinityFooter.module.css";

function XfinityFooter() {
  return (
    <nav className={style.main_nav}>
      <div className={style.container}>
        <img src={`/xfinity/logo.webp`} alt="Footer logo" />
        <div className={style.nav_logo}>
          <img src={`/xfinity/footer-logo.png`} alt="Footer logo" />
        </div>
      </div>
    </nav>
  );
}

export default XfinityFooter;
