
import Header from "./Header/Header";
import Section from "./section1/Section";
import Section2 from "./Section2/Section2";
import Area from "./Area/Area";
import Section4 from "./Section4/Section4";
import Footer from "./Footer/Footer";
import Popup from "../popup/Popup";
import Discliamer from "../DisclaimerSection/DisclaimerSection";
import logo from "../../themefolder/spectrum/logo2.png";
export default function Home() {
  return (
    <>
      <main>
        <Header number="+1(855)-549-0288" />
        <Section number="+1(855)-549-0288" />
        <Section2 number="+1(855)-549-0288" />
        <Area />
        <Section4 />
        <Discliamer provider="spectrum" />
        <Footer />

        <Popup
          color="#003057"
          url={logo}
          number="+1(855)-549-0288"
          text="Call to Cancel Spectrum"
        />
      </main>
    </>
  );
}
