import React from "react";
import style from "../Section2/Section2.module.css";
import doublearrow from "../../../themefolder/spectrum/double_arrow.png";
import ProductsWiFiRouter from "../../../themefolder/spectrum/Spectrum_Products_WiFi-Router_1.jpg";
function Section2({ number }) {
  return (
    <section className={style.container}>
      <div className={style.main}>
        <div className={style.sec_img}>
          <img
            className="img-fluid"
            src={ProductsWiFiRouter}
            alt="spectrum cable deals"
          />
        </div>
        <div className={style.sec_main_text}>
          <p className={style.spectrum_mbl}>SPECTRUM MOBILE™</p>
          <h2 className="heading-2">A Better Way to Mobile Is Here</h2>
          <div className={style.tick_icon1}>
            <img src={doublearrow} alt="Spectrum Internet service provider" />
            <p>Mix and match data plans with Unlimited or By the Gig options</p>
          </div>
          <div className={style.tick_icon1}>
            <img src={doublearrow} alt="Spectrum Internet service provider" />
            <p>Most reliable mobile service coast to coast</p>
          </div>
          <div className={style.tick_icon1}>
            <img src={doublearrow} alt="Spectrum Internet service provider" />
            <p>Unlimited nationwide talk and text</p>
          </div>
          <div className={style.tick_icon1}>
            <img src={doublearrow} alt="Spectrum Internet service provider" />
            <p>No contracts, added taxes or hidden fees</p>
          </div>
          <div className={style.phone}>
            <div className={style.number}>
              {/* <Link href={`tel:${number}`}> */}
              <img src={doublearrow} alt="Spectrum Internet service provider" />
              {number}
              {/* </Link> */}
            </div>
          </div>
          <div className={style.foot_text}>
            <p>
              ^^Savings based on single line comparison of unlimited plans among
              major nat’l carriers as of 08/2022: prepaid excl: data usage
              limits vary by carrier.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section2;
