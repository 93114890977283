import React from "react";
import Card from "react-bootstrap/Card";
import { MdErrorOutline } from "react-icons/md";
import "./BannerCard.css";
import data from "../../../data.json"; // Import the data directly
import "../Banner/Banner.css";

const BannerCard = () => {
  const Bannercarddata = data.Bannercarddata; // Access the array from the imported JSON object
  return (
    <section className=" section-BannerCard">
      <div className="container-fluid">
        <div className="row ">
          {Bannercarddata.map(
            (
              item,
              index // Use the array from the JSON object
            ) => (
              <div
                className="col-lg-4 col-md-4 col-sm-12 mb-lg-0 mb-md-5 mb-sm-5 mt-lg-0 mt-md-5 mt-sm-5"
                key={index}
              >
                <Card className="Banner-card">
                  <Card.Body>
                    <Card.Title className="card-heading my-3">
                      {item.heading}
                    </Card.Title>

                    <Card.Text className="card-txt gap-2">
                      {item.text}
                      <a href="#" className="text-dark mx-2">
                        <MdErrorOutline className="text-dark" />
                      </a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default BannerCard;
