import React from "react";
import "./CardsSection.css";
import CardsSectionImage1 from "../../../themefolder/CardsSectionImage1.jpeg";
import CardsSectionImage2 from "../../../themefolder/CardsSectionImage2.jpeg";

const CardsSection = () => {
  const cardsData = [
    {
      id: 1,
      title: "Find out if you qualify for discounted home internet",
      subtitle: "You could save up to $20/mo with Verizon Forward",
      linkText: "Call us for Learn more",
      imageUrl: CardsSectionImage1, // Update this path to the correct image path
    },
    {
      id: 2,
      title: "Save up to $300 a year with mobile & home",
      subtitle:
        "When you add select home internet plans to select 5G mobile plans.",
      linkText: "Call us for Learn more",
      imageUrl: CardsSectionImage2, // Update this path to the correct image path
    },
  ];

  return (
    <div className="w-100 d-flex justify-content-center bgColor3">
      <div className="cardsSection-main">
        <p className="cardsSection-title">More ways to save</p>
        <div className="cards-section">
          {cardsData.map((card) => (
            <div key={card.id} className="card">
              <img
                src={card.imageUrl}
                alt={card.title}
                className="card-image"
              />
              <div className="card-content">
                <h2 className="card-title">{card.title}</h2>
                <p className="card-subtitle">{card.subtitle}</p>
                <a href={`tel:${+18885322024}`} className="card-link">
                  {card.linkText} &gt;
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardsSection;
