import React from "react";
import "./SectionFive.css";
import { Link } from "react-router-dom";
const SectionFive = () => {
  return (
    <>
      <section className="SectionFive my-5">
        <div className="container SectionFive-container">
          <div className="two-links-rapo d-grid justify-content-center ">
            <ul className="gap-4">
              {/* <li className="text-white =">
                <a
                  className="text-white text-decoration-none"
                  href="/cancel-xfinity"
                >
                  Cancel-Xfinity
                </a>
              </li>
              <li className="text-white">
                <a
                  className="text-white text-decoration-none"
                  href="/cancel-spectrum"
                >
                  Cancel-Spectrum
                </a>
              </li> */}
              <li><Link className="text-white" to={'/cancel-spectrum'}>
                Cancel-Spectrum
              </Link></li>
             <li>
             <Link className="text-white" to={'/cancel-verizon'}>
                Cancel-Verizon
              </Link>
             </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionFive;
