import React from "react";
import "./Policies.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

function Policies() {
  return (
    <>
      <Navbar />
      <div className="w-full mt-4 d-flex justify-content-center">
        <div id="text-div" className="col-12 col-md-5 col-lg-6 px-3">
          <h1 className="fw-normal">Privacy Policy</h1>
        </div>
      </div>
      <div
        className="w-full d-flex justify-content-center align-items-center"
        id="policies-main"
      >
        <div id="Policies-text-main">
          <p>
            Privacy Policy for <a href="">cancel-internet.com</a>
          </p>
          <p id="policy-headings">Your Privacy Matters</p>
          <p>
            At <a href="">cancel-internet.com</a>, safeguarding your privacy is
            our priority. This Privacy Policy elaborates on our commitment to
            protecting your personal information while you explore our website
            or utilize our Internet services. By engaging with our services, you
            agree to the terms outlined in this policy.
          </p>
          <p id="policy-headings">Information Collection</p>
          <p>We gather various data types, including:</p>
          <p>
            Personal Data: Such as your name, contact, and billing information
            to facilitate account management and service provision.
          </p>
          <p>
            Usage Data: Information on your utilization of our services and
            website, including device and browser data, IP addresses, and
            interaction metrics.
          </p>
          <p>
            Cookies & Trackers: Technologies used to optimize user experience
            and gather engagement analytics.
          </p>
          <p id="policy-headings">Information Utilization</p>
          <p>We use the collected information to:</p>
          <p>
            Deliver Services: Ensure seamless provision, billing, and support of
            our Internet services.
          </p>
          <p>
            Enhance User Experience: Customize, innovate, and improve service
            delivery and user engagement.
          </p>
          <p>
            Marketing Communications: Provide updates, offers, and promotions,
            with an option for users to unsubscribe.
          </p>
          <p>
            Legal Adherence: Fulfill legal requirements and address disputes.
          </p>
          <p id="policy-headings">Information Disclosure</p>
          <p>
            We pledge not to sell your personal data. We may, however, disclose
            information to:
          </p>
          <p>
            Affiliates: Third-party entities involved in facilitating our
            services.
          </p>
          <p>
            Legal Entities: Comply with legal directives, court orders, or
            governmental regulations.
          </p>
          <p id="policy-headings">Data Safety</p>
          <p>
            We employ robust security protocols to guard your data, though we
            acknowledge that absolute online security is unattainable.
          </p>
          <p id="policy-headings">Your Options</p>
          <p>You are entitled to:</p>
          <p>
            Review, amend, or erase your personal information. Unsubscribe from
            promotional communications. Manage cookies through browser
            configurations. Minors’ Privacy.
          </p>
          <p>
            We do not cater to children below 13 and refrain from collecting
            their personal information.
          </p>
          <p id="policy-headings">Policy Modifications</p>
          <p>
            We may amend this Privacy Policy periodically. Kindly review the
            "Effective Date" to be apprised of any adjustments.
          </p>
          <p id="policy-headings">Reach Out</p>
          <p>
            For inquiries or concerns about this policy or your information,
            email us at <a href="">info@cancel-internet.com</a>
          </p>
          <p>
            We appreciate your trust in <a href="">cancel-internet.com</a>where
            your privacy is our esteemed commitment while we provide top-notch
            Internet services.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Policies;
