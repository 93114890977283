import React from "react";
import SectionFourimagetwo from "../../themefolder/berkeley-communications-WEDDt-u3q3o-unsplash.jpg";
import SectionFourimage from "../../themefolder/avi-richards-Z3ownETsdNQ-unsplash.jpg";
import "./SectionFour.css";
const SectionFour = () => {
  return (
    <>
      <section className="SectionFour mt-5">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6">
              <img className="img-fluid" src={SectionFourimage} alt="" />
            </div>
            <div className="col-lg-6">
              <div className="SectionFour-heading-txt-button-rapo mt-5">
                <h2>How to find the best ISP in your area?</h2>
                <p className="text-muted fw-bold text-start ">
                  Choosing the best ISP can be overwhelming with so many options
                  and questions. We simplify this process for you. Call us at
                  +1(855) 626-8081, and we'll make your search quick and
                  hassle-free.
                </p>
                <div className="OurServicesSection-button d-flex justify-content-start">
                  <a
                    className=" border border-white border-3"
                    href="tel:+1(888)-412-5051"
                  >
                    Call Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6">
              <div className="SectionFour-heading-txt-button-rapo mt-5 text-start">
                <h2>Unlock the Best in Internet and Cable TV</h2>
                <p className="text-muted fw-bold text-start">
                  Discover our affordable and reliable internet and cable TV
                  plans designed to meet your needs. Enjoy seamless browsing,
                  streaming, and entertainment with the best services tailored
                  for you. Contact us today and elevate your connectivity
                  experience.
                </p>
                <div className="OurServicesSection-button d-flex justify-content-start">
                  <a
                    className=" border border-white border-3"
                    href="tel:+1(888)-412-5051"
                  >
                    Call Us
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img className="img-fluid" src={SectionFourimagetwo} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionFour;
