import React from "react";
import "./Disclaimer.css";

const Disclaimer = () => {
  return (
    <div className="w-100 mt-5 d-flex justify-content-center">
      <div className="diclaimer-parent">
        <p className="text-center dislaimer-header">Disclaimer</p>
        <p className="text-center dislaimer-description">
          This is NOT an official website of Verizon but is the Official Site of
          <a
            href="https://verizonofficial.com/"
            className="ms-1 verizon-anchor"
          >
            cancel-internet.com
          </a>
          (Marketing Partner). We may send updates to the mobile number/email ID
          registered with us. The content is for information purposes only and
          does not constitute an offer to avail of any service. Prices mentioned
          are subject to change without notice and properties mentioned are
          subject to availability. Images are for representation purposes only.
          The logos and images used on this website are the exclusive property
          of their respective owners and are protected under applicable
          copyright laws. Verizon does not claim any ownership or rights to
          these materials and they are used on this website solely for
          informational purposes.
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
