import React from "react";
import "./TopHeader.css";
const TopHeader = () => {
  return (
    <>
      <header className="bg-light">
        <nav className="navbar navbar-light bg-light border-bottom border-gray border-2">
          <div className="container-fluid d-flex justify-content-center">
            <div className="top-header-text ">
              <p style={{padding: '0' , margin: '0'}}>Call Us :: (855) 626-8081 - (888) 598-3176</p>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default TopHeader;
