import Banner from "./Banner/Banner";
import XfinityFooter from "./Footer/XfinityFooter";
import Header from "./Header/Header";
import XfinityFAQS from "./XfinityFAQS/XfinityFAQS";
import XfinityWrapper from "./XfinityWrapper/XfinityWrapper";
import XfinityWrraper2 from "./XfinityWrapper2/XfinityWrapper2";
import XfinityBox from "./XfinityBundls/XfinityBox";
import Discliamer from "../DisclaimerSection/DisclaimerSection";
import Popup from "../popup/Popup";
import logo from "../../themefolder/xfinity/xfinity-authorized-dealer-logo.png";

export default function Xfinity() {
  return (
    <>
      <main className="xfinity_wrapper">
        <Header number="+1(888)-412-5051" />
        <Banner />
        <XfinityBox number="+1(888)-412-5051" />
        <XfinityWrapper number="+1(888)-412-5051" />
        <XfinityWrraper2 />
        <XfinityFAQS />
        <Discliamer provider="Xfinity" />
        <Popup
          color="linear-gradient(90deg,#ff7f7f 28%,#a80909)"
          url={logo}
          logoWidth="200px"
          number="1(888) 412-5051"
          text="Call to Cancel Xfinity Comcast Now"
        />
      </main>
    </>
  );
}
