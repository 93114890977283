import React from "react";
import "./SectionThird.css";
const SectionThird = () => {
  return (
    <>
      <section className="SectionThird ">
        <div className="container mt-5">
          <div className="SectionThird-info-box bg-dark mt-5">
            <div className="SectionThird-heading-text-buttons-rapo">
              <h2 className="mb-3">
                Tailoring Connections: Internet for Every Space
              </h2>
              <p className="mb-3">
                Every space, whether a cozy home or a dynamic business
                environment, deserves a reliable internet connection. We
                evaluate business internet providers and home internet options,
                delving into the specifications that distinguish them. Discover
                business internet plans crafted for seamless operations and home
                internet deals that combine speed, reliability, and
                affordability.
              </p>
              <div className="SectionThird-buttons-mainrapo d-flex justify-content-center gap-3 mt-3">
                <button
                  type="button"
                  class="btn SectionThird-button btn-secondary"
                >
                  <a className="callus-btn" href="tel:+1(888)-412-5051">
                    Call Us
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionThird;
