import React, { useState } from "react";
import "./Faqs.css";
import TopArrow from "../../../themefolder/topArrow.png";
import DownArrow from "../../../themefolder/downArrow.png";

const faqsData = [
  {
    id: 1,
    question:
      "Where is Verizon Home Internet available? And what home internet services are available to me?",
    answer: (
      <p className="faq-answer">
        It's all about the internet technology, how it's delivered to your home
        and where you live. Fios Home Internet is widely available in metro and
        suburban areas of New England and the Mid-Atlantic. 5G Home Internet is
        now in more and more cities around the country. Since LTE Home Internet
        uses the wireless 4G LTE network to deliver home broadband internet
        service, it is available in most parts of the country where Fios and 5G
        Home Internet are not available.
        <br />
        To see which Verizon Home Internet service is available where you live,
        enter your address above and click check availability.
      </p>
    ),
  },
  {
    id: 2,
    question: "What is 5G Home Internet?",
    answer: (
      <div className="info-section">
        <p>
          <a href="#">5G Home Internet</a> is ultra-fast, ultra-simple wireless
          home internet powered by 5G Ultra Wideband, giving you the
          ultra-powerful network performance and speed you need.
        </p>
        <ul>
          <li>
            Reliable and fast to power your whole home with lots of devices
            connected.
          </li>
          <li>
            No annual contracts, extra fees, data overages or equipment charges.
          </li>
          <li>
            Attractive pricing with Auto Pay and when you combine it with select
            5G Mobile plans.
          </li>
          <li>Service price guaranteed for up to 3 years.</li>
          <li>
            Get up to a $500 credit to help cover any early termination fees
            when switching.
          </li>
          <li>
            Simple plug and play self setup, with 30 days support included.
          </li>
        </ul>
        <p>
          <a href="#">5G Ultra Wideband</a> is now in more and more places
          around the country, so more people than ever can experience Verizon’s
          exceptional broadband internet at home.
        </p>
        <p>
          To see if your address qualifies, enter your address above and click
          check availability.
        </p>
      </div>
    ),
  },
  {
    id: 3,
    question: "What is Fios Internet?",
    answer: (
      <p className="faq-answer">
        Fios Internet is a 100% fiber-optic network that delivers some of the
        fastest internet speeds to millions of homes in the mid-Atlantic and New
        England. It uses fiber optics to transmit data at the speed of light,
        providing a faster internet connection and powerful bandwidth for
        multiple devices. Verizon is one of the only internet service providers
        to offer matching download and upload speeds on most of our plans, which
        is a major advantage when you’re video chatting, gaming and sharing
        large files or images.
      </p>
    ),
  },
  {
    id: 4,
    question: "What is LTE Home Internet?",
    answer: (
      <p className="faq-answer">
        LTE Home Internet is home broadband internet service that brings the
        Verizon 4G LTE network to your residence. It is available in certain
        areas where there are no other Verizon broadband options (Fios, 5G Home,
        etc.). With LTE Home Internet, you can stream video at 1080p, plus you
        can enjoy unlimited data with no data usage caps.
      </p>
    ),
  },
  {
    id: 5,
    question:
      "Can Verizon help cover my early termination fee when I switch to Verizon Home Internet?",
    answer: (
      <p className="faq-answer">
        Yes! If you are stuck in a contract for home internet, switch to Verizon
        Home Internet and get up to $500 credit to help cover your early
        termination fee. If you're eligible to get a bill credit for up to $500
        for an early termination fee charged by your previous home internet
        provider, here's how to get it. If you order Fios home internet, see the
        Fios Early Termination Fee instructions. If you order 5G Home, go to the
        5G Home Internet bill credit page.
        <br />
        You must submit your request within 90 days of your Verizon Home
        Internet setup.
      </p>
    ),
  },
  {
    id: 6,
    question:
      "Can I transfer my current Verizon Home Internet service to a new address?",
    answer: (
      <p className="faq-answer">
        We make it easy to stay connected. Check out our page on moving Verizon
        Home Internet service or log into your account to get started.
      </p>
    ),
  },
  {
    id: 7,
    question: "What is Verizon Whole-Home Wi-Fi?",
    answer: (
      <div className="info-section">
        <p>
          Verizon Whole-Home Wi-Fi includes 2 features that help you improve
          Wi-Fi coverage in your home:
        </p>
        <ul>
          <li>
            Wi-Fi Health Check in the My Verizon app, to optimize your Wi-Fi
            network.
          </li>
          <li>
            An optional Wi-Fi Extender* for better signal strength in
            hard-to-reach places.
          </li>
        </ul>
        <p>
          *If you cancel service or change to a plan that does not include
          Whole-Home Wi-Fi, you must return the Verizon-owned Wi-Fi Extender or
          you'll be charged an unreturned equipment fee of $175.
        </p>
      </div>
    ),
  },
  {
    id: 8,
    question: "What affordable Verizon Home Internet options are available?",
    answer: (
      <p className="faq-answer">
        Verizon participated in ACP, the Affordable Connectivity Program, while
        the program was active prior to the last fully funded month of April
        2024. Verizon now provides reduced-cost internet to eligible new or
        existing home Internet customers who have qualified for Lifeline, SNAP,
        WIC, and other assistance programs within 180 days of application or
        received a Federal Pell Grant within the past year with our Verizon
        Forward program. Qualifying customers receive a service discount on
        their Fios, 5G Home or LTE Home Internet plan price. Learn more here
      </p>
    ),
  },
];

const Faqs = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const handleToggle = (id) => {
    setOpenFaq(openFaq === id ? null : id);
  };

  return (
    <div className="w-100 d-flex justify-content-center faqs-main">
      <div className="w-90">
        <p className="Faqs-text">Have questions? We’ve got answers.</p>
        <div className="faqs-list">
          {faqsData.map((faq) => (
            <div key={faq.id} className="faq-item">
              <div
                className="faq-question"
                onClick={() => handleToggle(faq.id)}
              >
                {faq.question}
                <span className="faq-toggle">
                  {openFaq === faq.id ? (
                    <img
                      src={TopArrow}
                      width={21}
                      height={14}
                      alt=""
                      srcset=""
                    />
                  ) : (
                    <img
                      src={DownArrow}
                      width={21}
                      height={14}
                      alt=""
                      srcset=""
                    />
                  )}
                </span>
              </div>
              {openFaq === faq.id && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
