import React from "react";
import "./SectionOne.css";
const SectionOne = () => {
  return (
    <>
      <section className="section-one">
        <div className="container">
          <div className="banner-heading-text-buttons d-grid text-center justify-content-center align-item-center gap-2">
            <div className="banner-heading">
              <h2>
                Helping you to find quality
                <br /> internet providers
              </h2>
            </div>
            <div className="banner-text">
              <p>(855) 626-8081 - (888) 598-3176</p>
            </div>
            <div className="banner-button mt-2">
              <a
                className=" border border-white border-3"
                href="tel:+1(888)-412-5051"
              >
                Call Now
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionOne;
