export const data = [
  {
    id: 1,
    question: "What is Xfinity?",
    answer:
      "Xfinity by Comcast is a corporation that provides internet, TV, and phone services. In addition to this, you can also get Xfinity home security system at affordable prices.",
  },
  {
    id: 2,
    question: "Is Comcast and Xfinity the same thing?",
    answer:
      "Comcast and Xfinity are the same things, more or less. Comcast acts as the parent organization, while Xfinity takes the lead as the premier brand delivering a broad spectrum of services like cable TV, internet, phone, and additional utilities.",
  },
  {
    id: 3,
    question: "Is Xfinity available in my area?",
    answer:
      "While Xfinity has a considerable presence and expansive coverage across various states, the optimal approach to determine the availability in your vicinity is by entering your zip code.",
  },
  {
    id: 4,
    question: "How much does Xfinity cost after 12 months?",
    answer:
      "After a year of Xfinity service, you might see an increase in your monthly payment, which can depend on the plan you selected and your area.",
  },
  {
    id: 5,
    question: "How much does Xfinity cost?",
    answer:
      "Xfinity extends an array of services in various packages, and the monthly subscription cost is contingent upon the services and packages you choose. An effective strategy to trim down your Xfinity bills is to bundle your Xfinity services.",
  },
];
