import React from "react";
import Slider from "react-slick";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import FirstPlansCard from "../FirstPlansCard/FirstPlansCard";
import "./PlansCard.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SecondPlansCard from "../SecondPlansCard/SecondPlansCard";
import ThirdPlansCard from "../ThirdPlansCard/ThirdPlansCard";
import FourthPlansCard from "../FourthPlanCard/FourthPlanCard";

// Custom Next Arrow Component
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        padding: "10px",
        borderRadius: "50%",
        color: "black",
        right: "10px",
        zIndex: 1,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      }}
      onClick={onClick}
    >
      <FaArrowRight className="text-dark" />
    </div>
  );
};

// Custom Prev Arrow Component
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        padding: "10px",

        borderRadius: "50%",
        color: "black",
        left: "10px",
        zIndex: 1,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      }}
      onClick={onClick}
    >
      <FaArrowLeft />
    </div>
  );
};

const PlansCard = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="PlansCard mt-5">
      <div className="container">
        <Slider {...settings}>
          <div className="col-lg-3">
            <FirstPlansCard />
          </div>
          <div className="col-lg-3">
            <SecondPlansCard />
          </div>
          <div className="col-lg-3">
            <ThirdPlansCard />
          </div>
          <div className="col-lg-3">
            <FourthPlansCard />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default PlansCard;
