import React from "react";
import Navbar from "../Navbar/Navbar";
import "./CableTV.css";
import CableTVImage from "../../themefolder/erik-mclean-U-Vu_r6qyyU-unsplash.jpg";
import Footer from "../Footer/Footer";

function CableTV() {
  return (
    <>
      <Navbar />
      <div className="w-full mt-4 d-flex justify-content-center">
        <div id="text-div" className="col-12 col-md-5 col-lg-6 px-3">
          <h1 className="fw-normal">Cable TV</h1>
        </div>
      </div>
      <div className="w-full d-flex justify-content-center" id="main-text-div">
        <div
          className="row d-flex align-items-center justify-content-between px-2"
          id="main-div-cable"
        >
          <div className="col-12 col-md-7 col-lg-5">
            <img src={CableTVImage} alt="" srcset="" id="Cable-TV" />
          </div>
          <div className="col-11 col-md-5 col-lg-6" id="texts-div">
            <h2 id="header-text" className="fw-normal">
              Cable TV
            </h2>
            <p id="text-1">
              Explore Exceptional Entertainment with Our Exclusive Cable TV
              Offerings
            </p>
            <p id="text-2">
              Unlock a realm of unmatched entertainment with our Cable TV
              subscriptions, meticulously designed to cater to diverse viewing
              preferences. Each plan grants access to a world where quality,
              variety, and innovation converge to deliver an unparalleled
              viewing experience.
            </p>
            <p id="text-14">Tailored Content Selections</p>
            <p id="text-3">
              Recognizing the uniqueness of every viewer, we offer thoughtfully
              curated Cable TV packages featuring a blend of channels to suit
              all interests. From thrilling sports and captivating movies to
              insightful documentaries and engaging kids’ content, our offerings
              cater to every age and taste.
            </p>
            <p id="text-4">Impeccable Viewing Experience</p>
            <p id="text-5">
              Quality and reliability define our service. Enjoy crystal-clear
              images and flawless sound quality, backed by our steadfast
              commitment to uninterrupted viewing. Every moment spent in front
              of your screen promises to be immersive, drawing you into the
              diverse worlds presented by our channel lineup.
            </p>
            <p id="text-6">Over 290 Channels to Explore</p>
            <p id="text-7">
              Step into a universe boasting over 290 channels, each offering
              distinctive content meant to entertain, inform, and enchant. We
              don't just provide channels; we open doors to a myriad of worlds,
              ensuring every family member discovers their ideal entertainment
              niche.
            </p>
            <p id="text-8">Interactive Features for Enhanced Engagement</p>
            <p id="text-9">
              Our Cable TV service offers interactive features that elevate your
              viewing experience. Schedule recordings, customize your channel
              lineup, and access a wealth of on-demand content – every feature
              empowers you to tailor your entertainment choices.
            </p>
            <p id="text-10">Dedicated Customer Service</p>
            <p id="text-11">
              Your satisfaction is our priority. Our customer service team
              stands ready to address your queries and concerns, seizing each
              opportunity to enhance your viewing pleasure. With us, you're not
              just a viewer; you're valued and supported.
            </p>
            <p id="text-12">Immerse Yourself in Customized Entertainment</p>
            <p id="text-13">
              Join us and immerse yourself in a Cable TV experience where
              quality, diversity, and innovation thrive. Each channel, every
              show, invites you into a world where content reigns supreme, and
              viewer preferences are honored. Your screen becomes a gateway to a
              universe brimming with content tailored to resonate with your
              unique tastes. Welcome to an experience where every pixel and
              narrative is a dance, choreographed to celebrate the richness of
              your interests.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CableTV;
