import React from 'react'
import TopHeader from '../TopHeader/TopHeader'
import Navbar from '../Navbar/Navbar'
import SectionOne from '../SectionOne/SectionOne'
import OurServicesSection from '../OurServicesSection/OurServicesSection'
import SectionThird from '../SectionThird/SectionThird'
import SectionFour from '../SectionFour/SectionFour'
import SectionFive from '../SectionFive/SectionFive'
import DisclaimerSection from '../DisclaimerSection/DisclaimerSection'
import Footer from '../Footer/Footer'
import Faqs from "../SectionFaqs/SectionFaqs"

const home = () => {
  return (
    <div>
      <TopHeader />
      <Navbar />
      <SectionOne />
      <OurServicesSection />
      <SectionThird />
      <SectionFour />
      <SectionFive />
      <Faqs/>
      <DisclaimerSection/>
      <Footer/>
    </div>
  )
}

export default home
