import React from "react";
import "./Header.css";
import search from "../../../themefolder/search.png";

const Header = () => {
  return (
    <header className="header-main">
      <div className="header-one-main">
        <div className="header-first">
          <div className="first-div">
            <p className="personal">Personal</p>
            <p className="line">|</p>
            <p className="business">Business</p>
          </div>
          <div className="links-div">
            <p className="text-1">1-833-VERIZON</p>
            <p className="line">|</p>
            <a href="">Contact Us</a>
            <p className="line">|</p>
            <a href="">Support</a>
            <p className="line">|</p>
            <a href="">Stores</a>
            <p className="line">|</p>
            <a href="">Coverage map</a>
            <p className="line">|</p>
            <a href="">Español</a>
          </div>
        </div>
      </div>
      <div className="d-flex header-two-main mt-3">
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="header-two-links">
            <a href="" className="logo-text">
              Verizon
            </a>
            <a href="" className="links-two">
              Mobile
            </a>
            <a href="" className="links-two">
              Home Internet
            </a>
            <a href="" className="links-two">
              Shop
            </a>
            <a href="" className="links-two">
              Deals
            </a>
          </div>
          <div className="d-flex align-items-center justify-content-between w-20">
            <a href="" className="search-main">
              <input
                type="text"
                className="search-input"
                placeholder="Search Verizon"
              />
              <img src={search} width={15} alt="" srcset="" />
            </a>
            <a href="" className="searchSingle">
              <img src={search} width={15} alt="" srcset="" />
            </a>
            <a href="" className="signIn">
              Sign in
            </a>
            <a href="">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21.6 21.6"
                width={20}
              >
                <path
                  d="M19.63887,4.5507H5.74775L5.40107,2.85831,2.3249,1.79972,1.96113,2.85807l2.47217.85059L6.91719,15.84281a2.10574,2.10574,0,1,0,3.02588,2.43836h4.41406a2.112,2.112,0,1,0,0-1.18689H9.94307a2.104,2.104,0,0,0-1.937-1.51185l-.38378-1.87524,11.08691-1.32935Zm-3.26465,12.213a.924.924,0,1,1-.92438.924A.924.924,0,0,1,16.37422,16.76371Zm-8.44861,0H7.926a.92414.92414,0,1,1-.00037,0Zm9.77576-5.392L7.39711,12.60722,5.97676,5.66959h12.4024Z"
                  stroke="black"
                  stroke-width=".1"
                  fill="#000000"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center w-100 bg-Color1 py-2 mt-4">
        <div>
          <p className="header-three-main">
            Get Ray-Ban Meta smart glasses, on us |{" "}
            <a href="" className="anchor-offer">
              Offer details
            </a>
          </p>
        </div>
      </div>
      <div className="bg-Color2 w-100 py-4 d-flex justify-content-center">
        <div className="d-flex justify-content-between w-65">
          <div className="flex-col align-items-start">
            <p className="text-start mb-0 text-two">
              Get Verizon Home Services
            </p>
            <p className="mb-0 text-three">
              Check if Verizon Home Internet is available.
            </p>
          </div>
          <div className="input3-main d-none">
            <div className="d-flex justify-content-between">
              <p className="text-address">
                Enter street address
                <svg
                  class="StyledSVG-VDS__sc-1fane64-0 cdXMEE ms-1"
                  id="helpIcon"
                  role="img"
                  aria-hidden="true"
                  width={12}
                  aria-label="support-drawer icon"
                  viewBox="0 0 21.6 21.6"
                  fill="#000000"
                >
                  <path
                    d="M10.8,1.8c-5,0-9,4-9,9c0,1.7,0.5,3.4,1.4,4.8l-1.1,4.1l4-1.2c4.2,2.6,9.8,1.3,12.4-2.9s1.3-9.8-2.9-12.4
                    C14.1,2.3,12.5,1.8,10.8,1.8z M10.8,18.7c-1.6,0-3.2-0.5-4.6-1.4c-0.1,0-2.7,0.8-2.5,0.8c0-0.1,0.7-2.4,0.7-2.6
                    c-1-1.3-1.5-3-1.5-4.6c0-4.3,3.5-7.9,7.9-7.9s7.9,3.5,7.9,7.9S15.1,18.7,10.8,18.7L10.8,18.7z M11.9,14.8c0,0.6-0.5,1-1,1
                    c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1C11.4,13.8,11.9,14.2,11.9,14.8L11.9,14.8z M13.6,8.6c0,1.1-0.4,1.4-1,2c-0.7,0.6-1,1-1,2h-1.3
                    c0-0.7,0.1-1.4,0.5-2c0.6-0.7,1.4-0.9,1.4-1.9c0-0.8-0.6-1.3-1.2-1.3c-1,0-1.4,0.7-1.4,1.8H8.2c0-1.8,1.2-2.9,2.8-2.9
                    C12.5,6.3,13.6,7.2,13.6,8.6z"
                    stroke="none"
                    fill="#000000"
                  ></path>
                </svg>
              </p>
              <a href="" className="location-link">
                Use my location
                <svg
                  class="StyledSVG-VDS__sc-1fane64-0 cdXMEE"
                  role="img"
                  aria-hidden="false"
                  aria-label="location icon"
                  viewBox="0 0 21.6 21.6"
                  width={15}
                  fill="#000000"
                >
                  <title>location icon</title>
                  <path
                    d="M10.8,1.8a6.7138,6.7138,0,0,0-6.16418,9.36676c1.02069,2.197,6.217,8.63324,6.217,8.63324s4.70861-5.77271,6.007-8.48785a7.53134,7.53134,0,0,0,.65076-2.80158A6.71053,6.71053,0,0,0,10.8,1.8Zm5.04486,9.02679a54.25636,54.25636,0,0,1-4.991,7.169,63.17012,63.17012,0,0,1-5.18512-7.27466,5.58625,5.58625,0,1,1,10.7168-2.21057A6.4108,6.4108,0,0,1,15.84486,10.82679ZM10.8,5.175A3.375,3.375,0,1,0,14.175,8.55,3.375,3.375,0,0,0,10.8,5.175Zm0,5.625a2.25,2.25,0,1,1,2.25-2.25A2.25254,2.25254,0,0,1,10.8,10.8Z"
                    stroke="none"
                    fill="#000000"
                  ></path>
                </svg>
              </a>
            </div>
            <div className="w-100">
              <input type="email" className="w-100 emailInput" />
            </div>
          </div>
          <div className="getStarted-button">
            <a href={`tel:${+18885322024}`} className="buttonStarted">
              Call Us
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
