import React from "react";
import Logo from "../../themefolder/logo.png";
import "./Navbar.css";
import { HiOutlineShoppingBag } from "react-icons/hi2";

import { IoIosSearch } from "react-icons/io";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-white bg-white">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img  src={Logo} alt="" width={'100%'} height={'150px'}/>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
              {/* <Link to="/internet"> */}
                <a className="nav-link" href="/internet">
                  Internet
                </a>
                {/* </Link> */}
              </li>
              <li className="nav-item">
                <a className="nav-link" href="cabletv">
                  Cable TV
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="disclaimer">
                  Disclaimer
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="privacypolicy">
                  Privacy Policy
                </a>
              </li>
            </ul>

            <div className=" d-flex icon-rapo ">
              <div className="nav-icons">
                <a href="#">
                  <IoIosSearch />
                </a>
              </div>
              <div className="nav-icons">
                <a href="#">
                  <HiOutlineShoppingBag />
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
