import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "../PlansCard/PlansCard.css";
import imageone from "../../../themefolder/perk-tile-walmart-plus-membership-3x2-default.webp";
import imagetwo from "../../../themefolder/perk-tile-walmart-plus-membership-logo.svg";

function FourthPlansCard() {
  return (
    <Card className="FirstPlansCard" style={{ width: "100%" }}>
      <Card.Body>
        <div className="heading-txt-rapo d-flex justify-content-between mb-3">
          <div className="heading-rapo" style={{ width: "50%" }}>
            <h6>For easy shopping, savings & more</h6>
          </div>
          <div className="txt-rapo" style={{ width: "50%" }}>
            <h6>
              $40 <span>/line*</span>
            </h6>
            <span className="span-two">
              $2.95/line <br /> perk savings
            </span>
          </div>
        </div>
        <div className="red-box ">
          <h3>
            <span className="text-white">Unlimited Ultimate</span>
          </h3>
        </div>
        <div className="secondplancard-footer d-flex mt-3 gap-2">
          <div className="secondplancard-footer-first">
            <Card className="d-grid align-items-center">
              <Card.Img
                style={{ width: "100%" }}
                src={imageone}
                alt="Card image"
              />
              <Card.ImgOverlay>
                <Card.Title className="text-dark ">
                  Walmart+ Membership
                </Card.Title>

                <Card.Footer className="cardfooter">
                  <img className="img-fluid " src={imagetwo} alt="" />
                </Card.Footer>
              </Card.ImgOverlay>
            </Card>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default FourthPlansCard;
